<template>
  <div class="ant-card mt-5" style="height:150px;">
    <div class="card-header">
      <h1 class="float-left mt-3  ml-4">Email</h1>
    </div>

    <v-row class="mx-5 mt-3">
      <v-col>
        {{ user.email ? user.email : "N/A" }}
      </v-col>

      <v-col class="text-center">
        <v-btn color="green" depressed x-small>
          Verified
        </v-btn>
      </v-col>

      <v-col class="">
        <div class="text-center float-right">
          <v-dialog v-model="emailmodal" width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on">
                Change Email
              </v-btn>
            </template>

            <v-card>
              <v-form ref="emailchange">
                <v-card-title class="headline ">
                  Change Email
                </v-card-title>
               
                <v-card-text>
                   <v-alert
                    :type="actionClass"
                    class="mr-15"
                    v-if="message"
                    dismissible
                  >
                    {{ message }}
                  </v-alert>
                  <v-container>
                    <v-text-field
                      v-model= "password"
                      label="Enter Your password"
                      :type="showPassword ? 'text' : 'password'"
                      :append-icon="
                        showPassword ? 'mdi-visibility-off' : 'mdi-visibility'
                      "
                      @click:append="showPassword = !showPassword"
                      required
                      :error-messages="error.password"
                    ></v-text-field>

                    <v-text-field
                      v-model="email"
                      :rules="emailRules"
                      label="Enter new your Adress"
                      required
                    ></v-text-field>
                  </v-container>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="emailmodal = false">
                    Cancel
                  </v-btn>
                  <v-btn color="primary" text @click="changeEmail">
                    Submit
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import validationMxn from '@/mixins/validation_mixin';

export default {
  name: "emailComponent",
  mixins: [validationMxn],
  props: ['user'],
  data() {
    return {
      emailmodal: false,
      email: '',
      password: "",
      showPassword: false,
      emailRules: [
        v => !!v || "Email is required",
        v => /.+@.+/.test(v) || "E-mail must be valid"
      ],
      error: {},
      actionClass: '',
      message: '',
    };
  },
  computed: {
    ...mapGetters(['getSession', 'getErrors']),
  },
  watch: {
    getErrors(val) {
      this.error = val;
    }
  },
  mounted() {
    this.email = this.user.email;
  },
  methods: {
    async changeEmail() {
      if (!this.$refs.emailchange.validate()) {
       return;
      }
      const sessionData = this.getSession;
      const payload = {
        current_email: sessionData.email,
        email: this.email,
        password: this.password,
      };

      const  fullPayload = {
        params: payload,
        endpoint: `/auth/update-email/${sessionData.id}`,
      };
      
      const response = await this.performPutActions(fullPayload);
      this.actionClass = response.status === 'success' ? 'success' : 'error';
      this.message = response.message;
      this.email = response.data.email;
    }
  }
};
</script>
